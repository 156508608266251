import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ maxWidth: `640px`, marginBottom: `1.0rem`}}>
    <Image />
    </div>
    <p>Howdy! <br></br>I'm Ricky Reyna.</p>
    <p>I am software engineer, engineering leader and entrepreneur.</p>
    <p>I presently lead engineering at <a href="http://www.osano.com">Osano</a>. Osano is an easy-to-use data privacy platform that instantly helps your website become compliant with laws such as GDPR and CCPA. Osano works to keep you out of trouble and monitors all of the vendors you share data with – so you don’t have to.</p>
    <p>Previously, I led engineering at <a href="http://www.xchg.net">XCHG</a> where I led the building of a blockchain-enabled differentiated commodity registry that enables producers and and traders to exchange differentiated assets like Frack-Free Natural Gas.</p>
    <p>Before XCHG, I led product and engineering at <a href="http://www.promoter.io">Promoter.io</a>, where we sent millions of surveys on behalf of thousands of brands like Comcast, Docusign, Gusto and many others.</p>

  </Layout>
)

export default IndexPage
